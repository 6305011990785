import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/*
  This creates a timer that can be used to perform some action after a certain time of inactivity. In our case, this is used to log the user out.
  This is done by using a interval to save the time when the user should be logged out. This time is updated based on a few events.
  If the current time catches up with the time time to expire, then the user is logged out.
*/

export class IdleTimer {
    public timeout;
    public onTimeout;
    public eventHandler;
    public interval;
    public timeoutTracker;
    constructor({ timeout, onTimeout }) {
      // console.log("Constructor")
      this.timeout = timeout;
      this.onTimeout = onTimeout;
  
      this.eventHandler = this.updateExpiredTime.bind(this);
      this.tracker();
      this.startInterval();
    }
  
    startInterval() {
      this.updateExpiredTime();
      this.interval = setInterval(() => {
        const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
        // console.log(expiredTime);
        // console.log(Date.now())
        if (expiredTime < Date.now()) {
          if (this.onTimeout) {
            this.onTimeout();
            this.cleanUp();
          }
        }
      }, 1001);
    }
  
    updateExpiredTime() {
      // console.log("Tink");
      if (this.timeoutTracker) {
        clearTimeout(this.timeoutTracker);
      }
      const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
      // console.log(expiredTime)
      if(expiredTime == 0 || isNaN(expiredTime)){
        localStorage.setItem("_expiredTime", ""+ (Date.now() + this.timeout * 1000));
      }
      this.timeoutTracker = setTimeout(() => {
        localStorage.setItem("_expiredTime", ""+ (Date.now() + this.timeout * 1000));
    }, 1000);
    }
  
    tracker() {
      window.addEventListener("mousemove", this.eventHandler);
      window.addEventListener("scroll", this.eventHandler);
      window.addEventListener("click", this.eventHandler);
    }
  
    cleanUp() {
      // console.log("Cleanup");
      clearInterval(this.interval);
      localStorage.setItem("_expiredTime", ""+ 0);
      // const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
      // console.log(expiredTime)
      window.removeEventListener("mousemove", this.eventHandler);
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("click", this.eventHandler);
    }
}
  