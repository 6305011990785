import { initialUserState } from './../reducers/user/user.reducer';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { tap, map, catchError, retryWhen, mergeMap, filter } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { EnvService } from '../services/env.service';
import { User } from '../models/user';
import { pipe, Observable, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import UserActions from '../reducers/user/user.actions';
import { throwError, BehaviorSubject } from 'rxjs';
import { AppState } from '../models/appState';
import GlobalActions from '../reducers/global.actions';
import { IdleTimer } from '../services/timeout.service';
import { ErrorService } from '../services/error.service';
import MessagesActions from '../reducers/messages/messages.actions';

function alertFirstInstance(i){
  if(i === 0){
    alert("We're sorry, there was a problem. Retrying");
  }
  return timer(1000)
}

/*
  Authentification service sends requests to the backend, and stores/updates the
  current email for the other pages
*/

interface loginAPIResponse {
  first_name: string;
  login_status: string;
  token: string;
  access_token: string;
  refresh_token: string;
  access_token_creation_time: string;
  update_first_last: boolean;
}

interface newAccessTokenResponse {
  access_token: string;
  access_token_creation_time: string;
}

interface apiHTTPHeaders {
  headers: any;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: any;
  userState$: Observable<User>;
  user: User;
  authHeaders: apiHTTPHeaders;
  interval: any;
  timer: any;
  refreshTokenTime: number = 780000;
  numOfTries: number = 10;
  lastAccessed;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    private _store: Store<AppState>,
    private router: Router,
    private errorService: ErrorService
  ) {
    this.userState$ = this._store.select('user');
    // Subscribe to the user state
    this.userState$.subscribe((userState) => {
      this.user = userState;

      // Set the JWT to be across each request
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Authorization', `Bearer ${userState.token}`);

      this.authHeaders = {
        headers: httpHeaders,
      };

      const expiredTime = parseInt(localStorage.getItem("_expiredTime"), 10);
      // console.log("     ", expiredTime)
      // console.log(this.timer)
      // console.log(this.user.isLoggedIn)
      // console.log("Interval", this.interval);
      if(!this.interval && this.user.isLoggedIn){ //Interval bug fix
        this.getNewAccessToken();
      }
      if(!this.timer && this.user.isLoggedIn){ //Create inactivity timer
        this.timer = new IdleTimer({
          timeout: 12*3600, //expired after this time (in seconds)
          onTimeout: () => {
            // console.log("Timeout");
            if(!this.user.isDemo){
              this.logout();
            }
          }
        });
        // console.log("Started timer", this.timer)
      }
      if(this.user.access_token_creation_time != undefined){
        this.lastAccessed = this.user.access_token_creation_time;
      }
    });
  }

  /*
    Get user information
  */

  getUser() {
    return this.user;
  }

  /*
    Check if there was a problem with the interval that checks to refresh the token
    If the interval got deleted, then check if we are within the refresh interval and by how much.
    If outside the interval, log the user out.
    If a lot of time left in the interval, just ask for new interval
    If not a lot of time left, refresh token and create new interval
  */
  checkTokenRefresh(){ //This function fixes the problem with the token breaking if you refresh the page
    if(this.user.accessToken != ''){
      const tokenCreateTime = new Date(this.lastAccessed).getTime();
      const currentTime = new Date().getTime();
      const timeDiff = currentTime - tokenCreateTime;
      if(!this.interval){
        if(timeDiff && (timeDiff > this.refreshTokenTime - 60000) && (timeDiff < this.refreshTokenTime)){ //If it's time to refresh, send request to refresh
          // console.log("inside if");
          this.getNewAccessToken(true);
        }
        else if(timeDiff && timeDiff < this.refreshTokenTime - 60000){ //If not, do not refresh immediately, but do reset the interval
          // console.log("inside elif");
          this.getNewAccessToken(false);
        }
        else {
          // console.log("Should log out");
          this.logout();
        }
      }
    }
  }

  isLoggedIn() {
    return this.user.isLoggedIn;
  }

  updateFirstLastNeeded() {
    return this.user.update_first_last;
  }

  // isAuthed(){
  //   return this.user.isAuthed;
  // }

  /*
    Get access token
    The '' checks if the access token is currently being updated
    Might be worth to look into modifying this to promises
  */
  getAccessToken(){ //check if set interval is running; await if it is
    this.checkTokenRefresh();
    while(this.user.accessToken === ''){
    }
    return this.user.accessToken;
  }

  /*
    Create interval to refresh access token. If x is true, the token is immediately refrshed. Otherwise,
    a interval is created to refresh the token after that time
  */
  async getNewAccessToken(x: boolean=false){
    const tokenCreateTime = new Date(this.user.access_token_creation_time).getTime();
    const currentTime = new Date().getTime();
    let timeDiff = currentTime - tokenCreateTime;
    if(x){
      let interval = setInterval(()=>{
      this.refreshTokenAux();}, 0)
      setTimeout(()=>{clearInterval(interval);}, 50)
    }
    if(timeDiff == NaN){
        this.interval = setInterval(()=>{
        this.refreshTokenAux();
      }, this.refreshTokenTime);
    }
    else if(timeDiff < this.refreshTokenTime){
      this.interval = setInterval(()=>{
        this.refreshTokenAux();
        clearInterval(this.interval);
        this.interval = setInterval(()=>{
        this.refreshTokenAux();
      }, this.refreshTokenTime);
      }, this.refreshTokenTime-timeDiff);
    }
  }

  //Updates state to refrlect currently-updating token and calls function to ask backend for new token
  refreshTokenAux(){
      let access_token = this.user.accessToken;
      if(access_token!= ''){
        this._store.dispatch(UserActions.updateUser({
          isLoggedIn: true,
          accessToken: '',
          refreshToken: this.user.refreshToken,
          access_token_creation_time: '',
          update_first_last: this.user.update_first_last
        }))
        this.refreshToken(access_token)
      }
  }
  //Requests new token from backend
  async refreshToken(access_token: string){
    return await this.http
      .post(this.env.API_URL + '/refresh_token', {
        refresh_token: this.user.refreshToken,
        access_token: access_token,
      }).pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}))
        .subscribe((response)=>{
          if(response['refresh_token_status'] === "success"){
            this._store.dispatch(UserActions.updateUser({
              isLoggedIn: true,
              accessToken: response['access_token'],
              refreshToken: this.user.refreshToken,
              access_token_creation_time: response['access_token_creation_time'],
              update_first_last: this.user.update_first_last
            }))
          }
          else{
            var errCode = this.errorService.getErrorCode(response['refresh_token_failure'])
            if(errCode === "EC-10"){
              this.logout();
            } else {
              alert("Sorry, something went wrong. Error code: " + errCode);
            }
          }
        })
  }


  async login(email: string, password: string) {
    return await this.http
      .post(this.env.API_URL + '/login', {
        vj_email: email,
        vj_password: password,
      })
      .pipe(
        tap(({ first_name, login_status, token, access_token, refresh_token, access_token_creation_time, update_first_last }: loginAPIResponse) => {
          if (login_status === 'success') {
              this.getNewAccessToken();
              this._store.dispatch(
                UserActions.login({
                  // email,
                  // first_name,
                  isLoggedIn: true,
                  token: token,
                  accessToken: access_token,
                  refreshToken: refresh_token,
                  access_token_creation_time: access_token_creation_time,
                  update_first_last: update_first_last,
                })
              );
              this._store.dispatch(MessagesActions.resetMessagesState());
            // this.router.navigateByUrl('record');
          } else {
          }
        }),
        retryWhen(errors => errors.pipe(
          mergeMap((err, i) =>
            i + 1 === this.numOfTries
            ? throwError('Error from retry!')
            : alertFirstInstance(i)
          )
        )
      ), catchError((err) => {throw 'internet'})
      );
  }

  /*
   sends a request to the backend based on user entered information, and returns
   the result

   params: fname, lname, email, password - all entered on the register screen
 */

  register(fName: string, lName: string, email: string, password: string) {
    return this.http
      .post(this.env.API_URL + '/register', {
        vj_email: email,
        vj_password: password,
        first_name: fName,
        last_name: lName,
      })
      .pipe(
        tap(({ first_name, login_status, token, access_token, refresh_token, access_token_creation_time }: loginAPIResponse) => {
          if (login_status === 'success') {
          this.getNewAccessToken();
          this._store.dispatch(
            UserActions.login({
              // email,
              // first_name,
              isLoggedIn: true,
              token: token,
              accessToken: access_token,
              refreshToken: refresh_token,
              access_token_creation_time,
              update_first_last: false
            })
          );
          this._store.dispatch(MessagesActions.resetMessagesState());
          }
        }),
        retryWhen(errors => errors.pipe(
          filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'})
      );
  }

  //Google or facebook login or registration
  thirdPartyLogin(fName, lName, email, logOrRg, data){
    return this.http.post(this.env.API_URL + '/third_party_login_and_registration',{
      vj_email: email,
      first_name: fName,
      last_name: lName,
      login_or_register: logOrRg,
      third_party_payload: data,
    }, this.authHeaders
    ).pipe(
      tap(({ first_name, login_status, token, access_token, refresh_token, access_token_creation_time }: loginAPIResponse) => {

        if(access_token){
          this.getNewAccessToken();
        this._store.dispatch(
          UserActions.login({
            // email,
            // first_name,
            isLoggedIn: true,
            token: token,
            accessToken: access_token,
            refreshToken: refresh_token,
            access_token_creation_time,
            update_first_last: false
          })
        );
        }
      }),
      retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'})
    );
  }

  /*
   logs the user out of their account
 */

  logout() {
    if(this.interval){
      clearInterval(this.interval);
    }
    if(this.timer){
      this.timer.cleanUp();
      // delete this.timer;
    }
    this._store.dispatch(GlobalActions.globalClear());
    this.router.navigateByUrl('registration');
  }

  /*
  updates the user's email when information is entered from the settings screen

  params: new_email - the user's new requested email
          old_email - the user's current email
          password - the user's current password that will not change
*/
  updateEmail(new_email: string, old_email: string, password: string) {
    return this.http
      .post(this.env.API_URL + '/update_account_email', {
        new_email: new_email,
        vj_email: old_email,
        vj_password: password,
      })
      .pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

  /*
 updates the user's password when information is entered from the settings screen

 params: email - the user's current email
         old_password - the user's current password
         new_password - the user's new requested password
*/
  updatePassword(email:string, token: string, old_password: string, new_password: string) {
    return this.http
      .post(this.env.API_URL + '/update_account_password', {
        vj_email: email,
        new_password: new_password,
        token: token,
        vj_password: old_password,
      })
      .pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

  // authenticateToken(token: string){
  //   // console.log(token)
  //   // this._store.dispatch(
  //   //   UserActions.auth()
  //   // );
  //   // return {
  //   //   'authentication_status': 'success'
  //   // }

  //   return this.http.post(this.env.API_URL + '/validate_access_token', {
  //     access_token: token,
  //   }).pipe(tap((result)=>{
  //     if(result['validate_access_token_status'] === 'success'){
  //       this._store.dispatch(
  //           UserActions.auth()
  //         );
  //     }
  //     else{
  //       // console.log("Auth status", result['validate_access_token_failure'])
  //     }
  //   }));
  // }

  //Asks backend to send a refresh password email to the inputted token
  forgotPassword(email: string){
    return this.http
      .post(this.env.API_URL + '/reset_password_request', {
        vj_email: email
      }).pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

}
