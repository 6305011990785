import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { APIService } from '../../services/api.service';
import { AppState } from 'src/app/models/appState';
import { Store } from '@ngrx/store';
import AvatarsActions from 'src/app/reducers/avatars/avatars.actions';
import { RecordingStorageService } from 'src/app/services/recording-storage.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AudioService } from 'src/app/services/audio.service';
import { ErrorService } from 'src/app/services/error.service';

var DetectRTC = require('detectrtc')
DetectRTC.load(function() {
  DetectRTC.hasMicrophone; // (has microphone device!)
  DetectRTC.isMobileDevice;
});

/*
  Page responsible for communication with legacy avatar in mobile format
*/

@Component({
  selector: 'app-avatar-modal',
  templateUrl: './avatar-modal.page.html',
  styleUrls: ['./avatar-modal.page.scss'],
})
export class AvatarModalPage implements OnInit {
  @Input("name") name;
  @Input("image") image;
  @Input("index") index;
  @Input ("clipNumber") clipNumber;
  public terms: any;
  blockedMic: boolean;
  releaseMic: boolean = false;
  readyToRecord: boolean = true;
  currentlyRecording: boolean = false;
  audioFile: File;
  audioURL: string;
  public avatarSpeaking: boolean = false;
  public loadingAudio: boolean = false;
  audioURLSQueue: string [] = [];
  sessionID: string;
  private currentlyPlaying: HTMLAudioElement;
  imageLoaded: boolean = false;
  public storyImages;
  private navigatedAway: boolean = false;
  private urlArraySize: number;

  constructor(private modalController: ModalController,
    private _store: Store<AppState>,
    private authService: AuthService,
    public audioService: AudioService,
    private recordingStorageService: RecordingStorageService,
    private apiService: APIService,
    private errorService: ErrorService
  ) {
    this.apiService.getSessionID().subscribe((response)=>{
      if(response['generate_new_session_id_status'] === "success"){
        this.sessionID = response['session_id'];
      }
      else {
        var errCode = this.errorService.getErrorCode(response['generate_new_session_id_failure'])
        if(errCode === "EC-10"){
          this.authService.logout();
        } else {
          alert("Sorry, something went wrong. Error code: " + errCode);
        }
      }
    }, (err)=> {
      if(err === 'internet'){
        alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
      }})
  }

  ngOnInit() {
    let i = document.getElementById("avatar-profile");
    i.setAttribute('src', this.image);
    i = document.getElementById("avatar-profile-g");
    i.setAttribute('src', this.image);
    i = document.getElementById("avatar-profile-l");
    i.setAttribute('src', this.image);
    const pic = <HTMLInputElement>document.getElementById("imageUploadMobile");
    pic.addEventListener('change', (event)=>{ //This is for changing the profile picture
      var reader = new FileReader();
      var img = document.getElementById("avatar-profile-g");
      var img1 = document.getElementById("avatar-profile-l");
      var img2 = document.getElementById("avatar-profile");
      reader.onload = function(event) {
        // console.log("loaded")
        img.setAttribute('src', ''+event.target.result);
        img2.setAttribute('src', ''+event.target.result);
        img1.setAttribute('src', ''+event.target.result);
      }
      reader.readAsDataURL(pic.files[0]);//Event listener for onload then send url over
      reader.addEventListener('loadend', (event)=>{
        this.image = ""+reader.result;
        this._store.dispatch(AvatarsActions.updateImage({
          name: this.name,
          imageAddr: ''+reader.result
        }))
      })
      var file_name = this.authService.getAccessToken().concat("__##__", this.index.toString(), "__##__", this.sessionID, "__##__", pic.files[0].name);
      this.apiService.saveAvatarPic(pic.files[0], file_name).subscribe((response)=>{
        if(response['store_avatar_profile_status'] === 'success'){
        }
        else {
          var errCode = this.errorService.getErrorCode(response['store_avatar_profile_failure'])
          if(errCode === "EC-10"){
            this.authService.logout();
          } else {
            alert("Sorry, something went wrong. Error code: " + errCode);
          }
        }
      }, (err)=> {
        if(err === 'internet'){
          alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
        }})
    })
    // console.log("Clip number", this.clipNumber)
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
    if(this.avatarSpeaking){
      this.currentlyPlaying.pause();
    }
    if(this.loadingAudio){
      this.navigatedAway = true;
      this.loadingAudio = false;
    }
  }

  changeProfilePic(){
    if(this.index === 0){
      const pic = <HTMLInputElement>document.getElementById("imageUploadMobile");
      pic.click();
    }
  }
  startRecording() {
    if(this.currentlyPlaying){
      if(!this.currentlyPlaying.paused){
        this.currentlyPlaying.pause();
      }
    }
    this.audioURLSQueue.splice(0, this.audioURLSQueue.length);
    this.authService.checkTokenRefresh();
    // console.log("Do we have permission?", DetectRTC.isWebsiteHasMicrophonePermissions)
    // console.log('startRecording called');
    if(this.clipNumber === 0){
      this.audioService.startRecording("Avatar-request", () =>{
        this.blockedMic = true;
      }, ()=>{
        this.releaseMic = true;
      });
      this.waitForMicPermission(()=>{
        this.readyToRecord = false;
        this.currentlyRecording = true;
        this.avatarSpeaking = false;
      }, ()=>{
        alert("Please enable the mic in the settings and refresh the page in order to use the app.");
      })
    }
    else{
      alert("Your avatar is not ready to speak yet! Please return to the \"Record\" page and share more memories.");
    }

  }

  waitForMicPermission(success, failure){
    let interval = setInterval(()=>{
      if(this.blockedMic){
        clearInterval(interval);
        failure();
      }
      // DetectRTC.load(function(){
      //   DetectRTC.isWebsiteHasMicrophonePermissions
      // })
      // console.log(DetectRTC.isWebsiteHasMicrophonePermissions)
      if(this.releaseMic){
        clearInterval(interval);
        success();
      }
    }, 300)
  }

  async stopRecording() {
    this.readyToRecord = true;
    this.currentlyRecording = false;
    this.audioService.stopRecording();
    let subscription = this.audioService.getRecordedBlob().subscribe((recording) => {
      this.audioURL = URL.createObjectURL(recording['blob']);
      // console.log(recording);
      // console.log(this.audioURL);
      // console.log('Captured: ' + recording['title']);
      var file_name = this.authService.getAccessToken().concat("__##__", this.index.toString(), "__##__", this.sessionID, "__##__", recording['title']);
      // console.log(file_name)
      this.audioFile = new File([recording['blob']], file_name, {
        type: 'audio/webm'
      });
      // console.log("Audio file inside stopRecording", this.audioFile)
      this.loadingAudio = true;
      this.recordingStorageService.requestAvatarResponse(this.audioFile).subscribe((data: any)=>{

        if(data['sessionEnd'] === true){
          this.apiService.getSessionID().subscribe((response)=>{
            if(response['generate_new_session_id_status'] === "success"){
              this.sessionID = response['session_id'];
            }
            else {
              var errCode = this.errorService.getErrorCode(response['generate_new_session_id_failure'])
              if(errCode === "EC-10"){
                this.authService.logout();
              } else {
                alert("Sorry, something went wrong. Error code: " + errCode);
              }
            }
          }, (err)=> {
            if(err === 'internet'){
              alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
            }})
        }
        if(data['audio_clip_urls'].length > 0){
          if(!this.navigatedAway){
            this.urlArraySize = data['audio_clip_urls'].length;
            for (const element of data['audio_clip_urls']){
              this.audioURLSQueue.push(element)
            }
            if(data['image_urls'].length > 0){
              this.storyImages = data['image_urls'];
              this.imageLoaded = false;
            }
            else{
              this.imageLoaded = true;
            }
          }
        }
        else{
          this.loadingAudio = false;
          alert("Sorry, this avatar can't speak until you have recorded a total of at least ten stories. Please return to the Record page and share more of your memories!");
        }
        subscription.unsubscribe();

      })


    });
    this.currentlyPlaying= new Audio();
    await this.waitForURLS()
    this.handlePlay(this.audioURLSQueue.shift())

    this.audioService.stopRecording();
    this.audioService.teardownAudioMeter();

  }


//This function forces the website to wait for the urls to load
  waitForURLS(){
    return new Promise<void>((resolve, reject)=>{
      let interval = setInterval(()=>{
        if(this.audioURLSQueue && this.audioURLSQueue.length == this.urlArraySize){
          clearInterval(interval);
          resolve();
        }
        else if(!this.loadingAudio){
          reject();
        }
      }, 500)
    })
  }

  //Plays the audios received from the legacy avatar
  //Recursevely plays audio objects by popping them out of a queue
  handlePlay(audioURL){
    this.currentlyPlaying.src = audioURL;
    if(this.audioURLSQueue.length > 0){
      this.currentlyPlaying.onended = (event)=>{
        this.avatarSpeaking = false;
        this.handlePlay(this.audioURLSQueue.shift());
      }
      this.currentlyPlaying.onplaying = (event)=>{
        this.loadingAudio = false;
        this.avatarSpeaking = true;
        if(!this.imageLoaded){
          var modal = document.getElementById("myModalM");
          modal.style.display = "flex";
          var modalAux = document.getElementById("blur-headerM");
          modalAux.style.visibility = "visible";
          var span = <HTMLElement>document.getElementsByClassName("closeM")[0];
          span.onclick = function() {
            modal.style.display = "none";
            var modalAux = document.getElementById("blur-headerM");
            modalAux.style.visibility = "hidden";
          }
          this.showSlides(0);
          this.imageLoaded = true;
        }
      }
        this.currentlyPlaying.play();
    }
    else if(this.audioURLSQueue.length === 0){
      this.currentlyPlaying.onended = (event)=>{
        this.avatarSpeaking = false;
        var modal = document.getElementById("myModalM");
        modal.style.display = "none";
        var modalAux = document.getElementById("blur-headerM");
        modalAux.style.visibility = "hidden";
        this.imageLoaded = false;
      }
      this.currentlyPlaying.onplaying = (event)=>{
        this.loadingAudio = false;
        this.avatarSpeaking = true;
        if(!this.imageLoaded){
          var modal = document.getElementById("myModalM");
          modal.style.display = "flex";
          var modalAux = document.getElementById("blur-headerM");
          modalAux.style.visibility = "visible";
          var span = <HTMLElement>document.getElementsByClassName("closeM")[0];
          span.onclick = function() {
            modal.style.display = "none";
            var modalAux = document.getElementById("blur-headerM");
            modalAux.style.visibility = "hidden";
          }
          this.showSlides(0);
          this.imageLoaded = true;
        }
      }
      this.currentlyPlaying.play();
    }
  }

  //Show images if any are available with the current story audio
  showSlides(slideIndex) {
    // console.log(this.storyImages);
    var i;
    var slides = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("mySlides");
    // console.log(slides)
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    // if (slideIndex > slides.length) {slideIndex = 1}
    slides[slideIndex-1].style.display = "block";
    if((this.storyImages.length > 1) && slideIndex < slides.length)
      setTimeout(()=>{this.showSlides(slideIndex);}, this.storyImages[slideIndex].transition_point*1000); // Change image every 10 seconds
  }
}
