import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import {
  createEffect,
  Effect,
  Actions,
  OnInitEffects,
  ofType,
} from '@ngrx/effects';
import { createAction, Action, ActionReducer, Store, props } from '@ngrx/store';
import { defer, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppState } from '../models/appState';

const initialAppState = {
  user: {
    email: '',
    first_name: '',
    isLoggedIn: false,
  },
  hydrated: false,
};


/**
 * This is quite a complex re-write of https://github.com/natural-apptitude/ngrx-store-ionic-storage/tree/master/src
 * Given that Ionic storage is asynchronous, this is a multi-step process. It hinges on:
 *   A. An NGRX Effect running onInit, that hydrates the state after it runs a check
 *   B. A StorageSync meta reducer wrapping all incoming requests to
 *     i. Persist state after each change
 *     ii. Handle the actual hydration actions in order to pass them through the store
 *   C. Handling the actual storage within the @ionic/storage.
 */

const STORAGE_KEY = 'HEREAFTER_APP_STATE';

// I am unsure if this is the best way to reference this. Would love to sync this with the app.module instantiation
// but need to look further into it.

const storage = new Storage(
  {
    name: '__hereafterdb',
    driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
  },
  {}
);

// get/setNested inspired by
// https://github.com/mickhansen/dottie.js
function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    // Recurse into the object.
    const parts = path.split('.').reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    let pieces = path.split('.'),
      current = obj,
      piece,
      i,
      length = pieces.length;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}

function fetchState(): Promise<AppState> {
  return storage
    .get(STORAGE_KEY)
    .then((s) => s || initialAppState)
    .catch((err) => initialAppState);
}

function saveState(state: any, keys: string[]): Promise<void> {
  // Pull out the portion of the state to save.
  if (keys) {
    state = keys.reduce((acc, k) => {
      const val = getNested(state, k);
      if (val) {
        setNested(acc, k, val);
      }
      return acc;
    }, {});
  }

  return storage.set(STORAGE_KEY, state);
}

export const StorageSyncActions = {
  HYDRATE: createAction('[Ionic StorageSync] HYDRATE'),
  HYDRATE_SUCCESS: createAction(
    '[Ionic StorageSync] HYDRATE_SUCCESS',
    props<AppState>()
  ),
  HYDRATE_FAILURE: createAction('[Ionic StorageSync] HYDRATE_FAILURE'),
};

@Injectable()
export class StorageSyncEffects implements OnInitEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}
  ngrxOnInitEffects(): Action {
    // console.log('[NgRx Effect] Init Running');
    return StorageSyncActions.HYDRATE();
  }

  @Effect() hydrate$: Observable<any> = defer(() =>
    from(fetchState()).pipe(
      map(
        (returnedState) => {
          return {
            type: StorageSyncActions.HYDRATE_SUCCESS.type,
            payload: returnedState,
          };
        },
        catchError((e) => {
          console.warn(`error fetching data from store for hydration: ${e}`);

          return of({
            type: StorageSyncActions.HYDRATE_FAILURE,
            payload: {},
          });
        })
      )
    )
  );
}

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => {},
};

export function storageSync(options?: StorageSyncOptions) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign(
    {},
    defaultOptions,
    options || {}
  );
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');
  ignoreActions.push(StorageSyncActions.HYDRATE.type);
  ignoreActions.push(StorageSyncActions.HYDRATE_SUCCESS.type);
  ignoreActions.push(StorageSyncActions.HYDRATE_FAILURE.type);

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    // console.log('storageSyncReducer called with reducer:', reducer);
    return (state: any, action: any) => {
      const { type, payload } = action;
      let nextState: any;
      if (type === StorageSyncActions.HYDRATE_SUCCESS.type) {
        nextState = Object.assign({}, state, payload);
        // Pull out the keys we configured to save, and add them to this new state object
        for (let key in action) {
          if (keys.indexOf(key) !== -1) {
            nextState[key] = action[key];
          }
        }
        if (hydratedStateKey) {
          nextState[hydratedStateKey] = true;
        }
        // If this is a hydration success, we just return the hydrated state
        return nextState;
      } else {
        nextState = Object.assign({}, reducer(state, action));
      }

      if (ignoreActions.indexOf(type) === -1) {
        saveState(nextState, keys).catch((err) => onSyncError(err));
      }

      return nextState;
    };
  };
}
