import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from './env.service';
import { AuthService } from '../auth/auth.service';
import { Storage } from '@ionic/storage';
import { noop, Observable, of, Subscription, throwError, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { User } from '../models/user';
import { retryWhen, delay, take, catchError, mergeMap, tap, filter } from 'rxjs/operators';
import { AppState } from '../models/appState';
import { AlertService } from './alert.service';


//Ensure that only the first error shows a  message; returns a promise that resolves after the timer finishes
function alertFirstInstance(i){
  if(i === 0){
    alert("We're sorry, there was a problem. Retrying");
  }
  return timer(1000)
}

interface apiHTTPHeaders {
  headers: any;
}
@Injectable({
  providedIn: 'root',
})

/*
  The API service sends the user's selected messages to the backend along with
  any associated audio clips
*/
export class APIService {
  private userState$: Observable<User>;
  email: string = '';
  token: string = '';
  authHeaders: apiHTTPHeaders;
  private user: User;
  numofTries = 10;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    private authService: AuthService,
    private storage: Storage,
    private _store: Store<AppState>,
    private alertService: AlertService,
  ) {
    this.userState$ = this._store.select('user');
    this.userState$.subscribe((userState) => {
      // this.email = userState.email;
      this.user = userState;

      // Set the JWT to be across each request
      const httpHeaders = new HttpHeaders();
      httpHeaders.set('Authorization', `Bearer ${userState.token}`);

      this.authHeaders = {
        headers: httpHeaders,
      };
    });
  }

  /*
    sends the user's selected option to the backend to receive the next
    prompts

    params: option - the keyword for the option selected
            currentMessage - the message that the user most recently responded to
  */
  sendResponse(option: string, currentMessage: string) {
    this.authService.checkTokenRefresh();
    return this.http.post(
      this.env.API_URL + '/fetch_next_messages',
      {
        // vj_email: this.authService.getUserEmail(),
        access_token: this.authService.getAccessToken(),
        current_variable: option,
        current_message: currentMessage,
      },
      this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numofTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

  /*
    DEPRECATED
    sends the user's recording link to the backend
    params: recording_path - the local path of the recording blob
  */
  // storeRecording(recording_path: string) {
  //   return this.http.post(
  //     this.env.API_URL + '/store_recording',
  //     {
  //       vj_email: this.authService.getUserEmail(),
  //       recording_file_path: recording_path,
  //     },
  //     this.authHeaders
  //   );
  // }

  // storeAudioObject(audioURL: string, audioObj: any) {
  //   this.storage.set(audioURL, audioObj);
  //   return this.http.post(
  //     this.env.API_URL + '/store_audio_object',
  //     {
  //       vj_email: this.authService.getUserEmail(),
  //       audio_object: audioObj,
  //     },
  //     this.authHeaders
  //   );
  // }

  /*
  retrieves the user's listed recordings in the backend to display on the
  Recordings page
*/
  getRecordings() {
    this.authService.checkTokenRefresh();
    return this.http.post(
      this.env.API_URL + '/fetch_all_recordings',
      {
        // vj_email: this.authService.getUserEmail(),
        access_token: this.authService.getAccessToken(),
      },
      this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
  retrieves the user's listed categories to display on the Recordings page
*/
  getCategories() {
    this.authService.checkTokenRefresh();
    return this.http.post(
      this.env.API_URL + '/fetch_all_categories',
      {
        // vj_email: this.authService.getUserEmail(),
        access_token: this.authService.getAccessToken(),
      },
      this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    retreives the audio from the backend
  */
  getAudio(category, title){
    this.authService.checkTokenRefresh();
    return this.http.post(
      this.env.API_URL + '/fetch_audio',
      {
        // vj_email: this.authService.getUserEmail(),
        // vj_initial: this.authService.getUserInitial(),
        access_token: this.authService.getAccessToken(),
        category: category,
        title: title,
      }
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Modify the name of a recording
  */
  editRecording(title, newTitle){
    this.authService.checkTokenRefresh();
    return this.http.post(
      this.env.API_URL + '/edit_recording',
      {
        // vj_email: this.authService.getUserEmail(),
        access_token: this.authService.getAccessToken(),
        recording_name: title,
        new_recording_name: newTitle
      }
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Send delete recording request to backend
  */
  deleteRecording(title){
    this.authService.checkTokenRefresh();

    return this.http.post(
      this.env.API_URL + '/delete_recording',
      {
        // vj_email: this.authService.getUserEmail(),
        access_token: this.authService.getAccessToken(),
        recording_name: title
      }
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Ask backend for user's level and per-category level
  */
  getMemoryScores() {
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/fetch_memory_scores',{
      // vj_email: this.authService.getUserEmail(),
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Get terms of service form third party
  */
  getTermsofService(){
    let url = "https://www.iubenda.com/api/terms-and-conditions/70358273"
    return this.http.get(url).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));;
  }

  /*
    Get privacy policy form third party
  */
  getPrivacyPolicy(){
    let url = "https://www.iubenda.com/api/privacy-policy/70358273"
    return this.http.get(url).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));;
  }

  /*
    Get all the legacy avatars the user has access to
  */
  getAvatars(demoAccessToken = ''){
    let accessToken = '';
    if(demoAccessToken.length != 0){
      accessToken = demoAccessToken;
    }
    else{
      this.authService.checkTokenRefresh();
      accessToken = this.authService.getAccessToken();
    }

    return this.http.post(this.env.API_URL + '/fetch_legacy_avatar_contacts',{
      // vj_email: this.authService.getUserEmail(),
      access_token: accessToken,
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
    // const FakeAPIResults = new Observable<any>((fakeResults)=>{
    //   fakeResults.next({
    //     "avatar_status": "success",
    //     "avatars_payload": [
    //       {
    //         name: "Victoria",
    //         image:"../../assets/img/PersonRecording.JPG"
    //       },
    //       {
    //         name: "Kevin",
    //         image: "../../assets/img/PersonRecording.JPG"
    //       }
    //     ]
    //   })
    // })
    // return FakeAPIResults;
  }

  /*
    Ask backend for a new session ID for legacy avatar communication (converse page)
  */
  getSessionID(demoAccessToken = ''){
    let accessToken = '';
    if(demoAccessToken.length != 0){
      accessToken = demoAccessToken;
    }
    else{
      this.authService.checkTokenRefresh();
      accessToken = this.authService.getAccessToken();
    }

    return this.http.post(this.env.API_URL + '/generate_new_session_id',{
      // vj_email: this.authService.getUserEmail(),
      access_token: accessToken,
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Send a preference update to the backend
  */
  sendPreference(option, variable){
    this.authService.checkTokenRefresh();
    return this.http.post(this.env.API_URL + '/set_user_preference',{
      access_token: this.authService.getAccessToken(),
      preference_variable: variable,
      preference_value: option,
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Save legacy avatar's profile picture
  */
  saveAvatarPic(image: File, imageName: string){
    this.authService.checkTokenRefresh();

    var audioData = new FormData();
    audioData.append('image_object', image, imageName);
    // audioData.append('audio_object', audioFile, "vj_email");
    // audioData.append('audio_object', "email value");
    // console.log('--------Audio form before request:');
    let httpHeaders = new HttpHeaders();

    return this.http
      .post(this.env.API_URL + '/store_avatar_profile', audioData, {
        headers: {
          enctype: 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${this.user.token}`,
        },
      }).pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numofTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

  /*
    Get user preferences from backend to populate in settings page
  */
  getPreferences(){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/fetch_preferences',{
      // vj_email: this.authService.getUserEmail(),
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Save image from image upload record page pipeline
  */
  saveStoryImage(image: File, imageName: string){
    this.authService.checkTokenRefresh();

    var audioData = new FormData();
    audioData.append('image_object', image, imageName);
    // audioData.append('audio_object', audioFile, "vj_email");
    // audioData.append('audio_object', "email value");
    // console.log('--------Audio form before request:');
    let httpHeaders = new HttpHeaders();

    return this.http
      .post(this.env.API_URL + '/store_user_image', audioData, {
        headers: {
          enctype: 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${this.user.token}`,
        },
      }).pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) =>
          i + 1 === this.numofTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
  }

  /*
    Request creation of checkout session with Stripe; will reroute user to stripe payment page
  */
  checkout(lookup_key){ //Plans page; Get Started button
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/create_checkout_session',{
      lookup_key: lookup_key,
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Request account deletion
  */
  deleteAccount(){ //Settings page; Plans and billing
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/delete_account_request',{
      access_token: this.authService.getAccessToken()
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Request creation of dashboard page; will route user to Stripe dashboard
  */
  updatePayment(sessionId){ //Settings page; Plans and billing
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/create_portal_session',{
      access_token: this.authService.getAccessToken(),
      session_id: sessionId
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Remove access to legacy avatar
  */
  deleteLegacyAvatar(index){ //Settings page; Access, trashcan
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/delete_legacy_avatar',{
      la_index: index,
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Invite user to access user avatar using email
  */
  invite(email){ //Settings page; Access, invite
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/invite_new_user',{
      invite_email: email,
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Get user`s current plan information
  */
  getPlans(){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/fetch_plan_level',{
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));

    // const FakeAPIResults = new Observable<any>((fakeResults)=>{
    //     fakeResults.next({
    //       "fetch_plan_level_status": "success",
    //       "plan": 'yr_unlimited'
    //     })
    //   })
    //   return FakeAPIResults;
  }

  /*
    Get list of all avatars user has access to
  */
  getAccessAvatars(){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/fetch_legacy_avatar_subscribers',{
      access_token: this.authService.getAccessToken(),
    }, this.authHeaders
    ).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Allow user to remove someone's access to their avatar
  */
  removeAccess(avatar){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/delete_legacy_avatar_subscriber', {
      access_token: this.authService.getAccessToken(),
      target_contact_index: avatar,
    }, this.authHeaders).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Allow user to remove their access to a legacy avatar
  */
  removeAvatar(avatar){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/delete_legacy_avatar_contact', {
      access_token: this.authService.getAccessToken(),
      target_contact_index: avatar,
    }, this.authHeaders).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  /*
    Update user's first and/or last name
  */
  updateFirstLast(firstName, lastName){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/update_first_last', {
      access_token: this.authService.getAccessToken(),
      first_name: firstName,
      last_name: lastName,
    }, this.authHeaders).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

  purchasePlan(payload){
    this.authService.checkTokenRefresh();

    return this.http.post(this.env.API_URL + '/process_apple_in_app_purchase_transaction', {
      access_token: this.authService.getAccessToken(),
      appleInAppPurchasePayload: payload

    }, this.authHeaders).pipe(retryWhen(errors => errors.pipe(
      filter(value => value.status === 0),
      mergeMap((err, i) =>
        i + 1 === this.numofTries
        ? throwError('Error from retry!')
        : alertFirstInstance(i)
      )
    )
  ), catchError((err) => {throw 'internet'}));
  }

}
