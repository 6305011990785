import { createAction, props } from '@ngrx/store';
import { Avatar } from 'src/app/pages/listen/listen.page';

interface updateState{
  avatars: any;
}

interface updateImage{
  name: string;
  imageAddr: string;
}

interface eraseAvatar {
  index: number;
}

export const AvatarsActions = {
    syncBackend: createAction(
      '[Avatars] Update state backend',
      props<updateState>()
    ),
    updateImage: createAction('[Avatars] Update image', props<updateImage>()),
    eraseAvatar: createAction('[Avatars] Erase Avatar', props<eraseAvatar>()),
  };
  
  export default AvatarsActions;