import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  constructor() {
    // console.log('hola1');
    let dos = setTimeout(() => {
      // console.log('hola3');
    }, 500);
    // console.log('hola2');
  }
}
