import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  UrlSegment,
  Route,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { AppState } from "../models/appState";

import { AuthService } from "./auth.service";

/*
  Block certain pages of the app to require login
*/
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  hydrationState$: Observable<boolean>;

  constructor(
    private router: Router,
    private authService: AuthService,
    private _store: Store<AppState>,
  ) {
    this.hydrationState$ = this._store.select("hydrated");
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
    // console.log(
    //   "[AuthGuard] Called trying to access route:",
    //   route.routeConfig.path,
    //   "from url:",
    //   state.url
    // );

    let resolveCallback;

    let promise = new Promise<boolean>((resolve, reject) => {
      resolveCallback = resolve;
    });
    let self = this;
    function handleHydrationResult(isHydrated) {
      // console.log("hydration check result");
      // console.log(isHydrated);
      if (isHydrated) {
        //unsubscribe
        // hydrationSubscription.unsubscribe();
        const isAuthenticated = self.authService.isLoggedIn();
        const updateNameNeeded = self.authService.updateFirstLastNeeded();
        // const isAuthed = self.authService.isAuthed(); 
        // console.log("auth guard running for route: ", route.routeConfig.path);
        // // console.log("user has token? ", isAuthed);
        // console.log("user is logged in? ", isAuthenticated);
        // if(route.routeConfig.path == "login" || route.routeConfig.path == "registration" || route.routeConfig.path == "forgot-password"){
        //   if(isAuthed){
        //     resolveCallback(true)
        //   }
        //   else{
        //     self.router.navigate(['auth'])
        //   }
        // }
        // else{
        //   if (isAuthed && isAuthenticated) {
        //     // Redirect them home from pages that don't make sense to see if you're logged in
        //     resolveCallback(true);
            
        //   } else {
        //     self.router.navigate(["auth"]);
        //     // You can't see this paths if not logged in
        //     resolveCallback(false);
        //   }
        // }

        if (isAuthenticated && !updateNameNeeded) {
          // Redirect them home from pages that don't make sense to see if you're logged in
          resolveCallback(true);
          
        } else {
          self.router.navigate(["login"]);
          // You can't see this paths if not logged in
          resolveCallback(false);
        }
        
      }
    }

    const hydrationSubscription = this.hydrationState$.subscribe(
      handleHydrationResult
    );

    return promise;
  }
}
