import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpModalPage } from 'src/app/pages/help-modal/help-modal.page';

/*
  Header for mobile navigation; hamburguer refrers to the lines used to open the menu.
*/

@Component({
  selector: 'app-drawer-header-menu',
  templateUrl: './drawer-header-menu.component.html',
  styleUrls: ['./drawer-header-menu.component.scss'],
})
export class DrawerHeaderMenuComponent implements OnInit {
  @Input() showHamburger: boolean;
  @Input() showBackButton: boolean;
  @Input() showHelp: boolean;
  hamburgerOpen = false;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (this.showHamburger === undefined) {
      this.showHamburger = true;
    }
    if (this.showBackButton === undefined) {
      this.showBackButton = false;
    }
    if (this.showHelp === undefined) {
      this.showHelp = true;
    }
  }

  onHamburgerClick() {
    this.hamburgerOpen = !this.hamburgerOpen;
  }

  async handleHelpClick() {
    const helpModal = await this.modalController.create({
      component: HelpModalPage,
      cssClass: "helpModal",
    });
    helpModal.present();
  }
}
