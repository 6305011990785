import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit } from '@angular/core';

/*
  Desktop sidebar; each status changes the color of the lettering in the bar to reflect the current page
*/

@Component({
  selector: 'app-desktop-sidebar-menu',
  templateUrl: './app-desktop-sidebar-menu.component.html',
  styleUrls: ['./app-desktop-sidebar-menu.component.scss'],
})
export class AppDesktopSidebarMenuComponent implements OnInit {
  public statusA: boolean; //record
  public statusB: boolean; //library
  public statusC: boolean; //memory score
  public statusD: boolean; //listen
  public statusE: boolean; //settings
  public statusF: boolean; //plans
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    if(this.router.url == '/record'){
      this.statusA = true;
      this.statusB = false;
      this.statusC = false;
      this.statusD = false;
      this.statusE = false;
      this.statusF = false;
    }
    else if(this.router.url == '/library'){
      this.statusA = false;
      this.statusB = true;
      this.statusC = false;
      this.statusD = false;
      this.statusF = false;
      this.statusE = false;
    }
    else if(this.router.url == '/memory-score'){
      this.statusA = false;
      this.statusB = false;
      this.statusC = true;
      this.statusD = false;
      this.statusF = false;
      this.statusE = false;
    }
    else if(this.router.url == '/listen'){
      this.statusA = false;
      this.statusB = false;
      this.statusC = false;
      this.statusD = true;
      this.statusF = false;
      this.statusE = false;
    }
    else if (this.router.url== '/settings'){
      this.statusA = false;
      this.statusB = false;
      this.statusC = false;
      this.statusF = false;
      this.statusD = false;
      this.statusE = true;
    }
    else if (this.router.url== '/plans'){
      this.statusA = false;
      this.statusB = false;
      this.statusC = false;
      this.statusF = true;
      this.statusD = false;
      this.statusE = false;
    }
  }

  handleNavigate(url) {
    this.router.navigateByUrl(url);
    
  }
  
  handleLogout() {
    this.authService.logout();
  }
}
