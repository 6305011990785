import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/*
  The link to the EC2 instance that recieves all of the requests to the
  backend
*/
export class EnvService {
  /*
  This is now being handled by a proxy as set up in the proxy.conf.json
   and angular.json config files, in order to avoid cors issues.
  */
  API_URL = 'https://virtualjournalist.ngrok.io';
  S3_URL = 's3://hereafteraudio/vj_audio/customers/';
  constructor() {}
}
