import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user';

interface loginInput {
  email: string;
  password: string;
}

export const UserActions = {
  // Auth
  loginStart: createAction('[User]  Login Start', props<loginInput>()),
  login: createAction('[User] Login', props<User>()),
  loginSuccess: createAction('[User]  Login Success'),
  loginFailure: createAction('[User]  Login Failure'),
  logout: createAction('[User] Logout'),
  setDemo: createAction('[User] Set as Demo'),
  // auth: createAction('[User] Authenticate'),
  // Handling user object
  clearUser: createAction('[User Storage] Logout User'),
  updateUser: createAction('[User] Update User', props<User>()),
  updateFirstLast: createAction('[User Storage] Set update_first_last to false'),
};

export default UserActions;
