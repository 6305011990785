import { createAction, props } from '@ngrx/store';
import { RecordingsState } from '../../models/recordingsState';

interface updateState{
  recordings: any;
}

interface recordingRef {
  title: string;
  category: string;
}

interface recordingUpdateData {
  category: string;
  recordingIndex: number;
  newName: string;
}

interface recordingLookupData {
  category: string;
  recordingIndex: number;
}

export const RecordingsActions = {
  storeRecording: createAction(
    '[Recordings] Store Recording',
    props<recordingRef>()
  ),
  editRecordingName: createAction(
    '[Recordings] Edit Recording Name',
    props<recordingUpdateData>()
  ),
  deleteRecording: createAction(
    '[Recordings] Delete Recording',
    props<recordingLookupData>()
  ),
  updateRecordings: createAction(
    '[Recordings] Update state backend',
    props<updateState>()
  )
};

export default RecordingsActions;
