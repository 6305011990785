import { createReducer, on, State, Action } from '@ngrx/store';
import { RecordingsState } from '../../models/recordingsState';
import RecordingsActions from './recordings.actions';
import GlobalActions from '../global.actions';

const initialRecordingsState: RecordingsState = {
  allSynced: false,
  categories: {
    Childhood: {
      recordings: [],
    },
    Teens: {
      recordings: [],
    },
    'Higher Ed': {
      recordings: [],
    },
    Work: {
      recordings: [],
    },
    Fun: {
      recordings: [],
    },
    Relationships: {
      recordings: [],
    },
    'Immediate Family': {
      recordings: [],
    },
    Relatives: {
      recordings: [],
    },
    Personality: {
      recordings: [],
    },
    'Advice & Values': {
      recordings: [],
    },
    Emotions: {
      recordings: [],
    },
    Other: {
      recordings: [],
    }
  },
};


// function addArrayItem(array, item) {
//   // This must be used in order to keep the state immutable. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns for more info
//   return [...array.slice(0), item];
// }

// function updateObjectInArray(array, action) {
//   // This must be used in order to keep the state immutable. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns for more info
//   return array.map((item, index) => {
//     if (index !== action.index) {
//       // This isn't the item we care about - keep it as-is
//       return item;
//     }

//     // Otherwise, this is the one we want - return an updated value
//     return {
//       ...item,
//       ...action.item,
//     };
//   });
// }

const categoryMappings = new Map([ //Hardcoded category mapping; make sure to update this everytime a new category is added.
  ['Higher Ed', ['College', 'Education', 'College Major', 'Graduate School', 'Med School', 'Vocational School', 'School']],
  ['Personality', [ "Sayings", 'Songs', 'Jokes', 'What You Dislike', 'What You Like', 'Facts']],
  ['Childhood', ['Earliest Memory', 'Childhood Home', 'Elementary School', 'Hometown', 'Your Birth']],
  ['Teens', ['First Kiss', 'High School', 'Prom', 'Junior High']],
  ['Work', ['Career', 'First Job', 'Military']],
  ['Fun', ['Fun','Celebrations', 'Celebration', 'Birthday', 'Halloween', "New Year's Eve", 'Fourth Of July', 'Interests', 'Travelling', 'Pets', 'Christmas', 'Food', 'Hanukkah', 'Movies And Tv', 'Music', 'Reading', 'Sports', 'Travel']],
  ['Relationships', ['Friends', 'Girlfriend', 'Boyfriend', 'First Love', 'Husband', 'Wife', 'Ex-Husband', 'Ex-Wife', 'Honeymoon', 'Wedding', 'Marriage Proposal', 'Best Friend', 'Met Your Husband', 'Met Your Wife', 'Dating']],
  ['Immediate Family', ['Brother', 'Sister', 'Siblings', 'Son', 'Daughter', 'Children', 'Mother', 'Father', 'Parents', 'Stepfather', 'Stepmother', 'Family']],
  ['Relatives', ['Ancestry', 'Aunt', "Mother's Ancestry", "Father's Ancestry", 'Grandson', 'Granddaughter', 'Grandfather', 'Grandmother', 'Grandchildren', 'Uncle', 'Cousins', 'Grandparents']],
  ['Advice & Values', ['Advice', 'Inspires', 'Remembered For', 'Reflections', 'Spirituality', 'Marital Advice', 'Parenting Advice', 'Professional Advice', 'Faith']],
  ['Emotions', ['Weakest', 'Bravest', 'Proudest', 'Hardest', 'Happiest', 'Saddest', 'Scariest', 'Embarrassing', 'Loneliest', 'Funniest', 'Strangest', 'Stupidest', 'Failure', 'Regret', 'Smartest']],
  ['Other', ['Death', 'Illness', 'Miracle', 'Wildcards', 'Userchoice', 'Other', 'Historical', 'Final Conversation', 'Message', 'War', 'Rewards', 'Ayn Rand']]
]);


const _recordingsReducer = createReducer(
  initialRecordingsState,
  on(RecordingsActions.storeRecording, (state, { category, title }) => {
    // Update its array of recordings to add in the new storage ref
    // console.log("[Reducer] starting the logger");
    // console.log("[Reducer] Here are some useful log statements:");
    // console.log(state);
    // console.log(state.categories);
    // console.log(state.categories[category]);
    // console.log("[Reducer] Here is the category");
    category = category.replace('20', ' ');
    // console.log(category);
    // console.log("[Reducer] category after parsing");
    let originalCategory = category;
    // console.log(title);
    title = title.split('__##__').slice(2).join('_')
    // console.log(title);
    for(let[key, value] of categoryMappings.entries()){
      if(value.includes(category)){
        category = key;
      }
    }


    // console.log(category);
    // console.log(state.categories[category]);
    // console.log(title);


    // console.log("[Reducer] Here is the end log in the reducer");

    let newState = state;
    if (state.categories[category] == undefined) {
      // console.log("it is undefined");

    } else {
      // console.log("here is the else case, which means it is defined");
      newState = {
        ...state,
        categories: {
          ...state.categories,
          [category]: {
            ...state.categories[category],
            recordings: [
              ...state.categories[category].recordings.slice(),
              {
                title,
                originalCategory: originalCategory,
              },
            ],
          },
        },
      };
    }


    // console.log('Trying to persist to: ');
    // console.log(newState);
    return newState;
  }),
  on(
    RecordingsActions.editRecordingName,
    (state, { category, recordingIndex, newName }) => {
      const newState = {
        ...state,
        categories: {
          ...state.categories,
          [category]: {
            ...state.categories[category],
            recordings: state.categories[category].recordings.map(
              (recording, idx) => {
                if (idx !== recordingIndex) return recording;
                else {
                  return {
                    ...recording,
                    title: newName,
                  };
                }
              }
            ),
          },
        },
      };
      // console.log(newState);
      return newState;
    }
  ),
  on(
    RecordingsActions.deleteRecording,
    (state, { category, recordingIndex }) => {
      const newState = {
        ...state,
        categories: {
          ...state.categories,
          [category]: {
            ...state.categories[category],
            recordings: state.categories[category].recordings.filter(
              (item, idx) => idx !== recordingIndex
            ),
          },
        },
      };
      // console.log(newState);
      return newState;
    }
  ),
  on(GlobalActions.globalClear, (state) => {
    // Refresh the messages - i.e. start from the top
    let newState = initialRecordingsState;
    return newState;
  }),
  on(RecordingsActions.updateRecordings, (state, {recordings})=>{ //Get recordings form backend and show in library page
    //console.log(state, recordings)
      // console.log("[Reducer] Sync with backend")
      // console.log("Backend response:", recordings)
      let newState = state;
      if(!state['allSynced']){
        recordings.recordings_payload.forEach( (element) => {
          state = newState
          let category = element.recording_categories[0]
          let title = element.recording_name
          category = category.replace('20', ' ');
          let originalCategory = category
          for(let[key, value] of categoryMappings.entries()){
            if(value.includes(category)){
              category = key;
            }
          }
          // console.log(element)
          // console.log(category)
          let result = state['categories'][category]['recordings'].find(obj => { return obj.title == title});
          // console.log("result", result)
          if(result == undefined){
            newState = {
              ...state,
              categories: {
                ...state.categories,
                [category]: {
                  ...state.categories[category],
                  recordings: [
                    ...state.categories[category].recordings.slice(),
                    {
                      title,
                      originalCategory: originalCategory,
                    },
                  ],
                },
              },
            };
          }
        })
        state = newState;
        newState = {
          ...state,
          allSynced: true
        }
      }

      // console.log("Persisting to:", newState);
      return newState;
  })
);

export default function recordingsReducer(state, action) {
  return _recordingsReducer(state, action);
}
