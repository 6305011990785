import { AppWideComponentsModule } from './components/app-wide-components/app-wide-components.module';
import { AuthGuard } from './auth/auth.guard';
import { SyncedStoreModule } from './reducers/index';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy} from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

import { File } from '@ionic-native/File/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Media } from '@ionic-native/media/ngx';
import { StreamingMedia } from '@ionic-native/streaming-media/ngx';
import { AudioService } from './services/audio.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StorageSyncEffects } from './reducers/ionic-storage-sync';
import {AvatarModalPage} from './pages/avatar-modal/avatar-modal.page'
import { ImageUploadModalPage } from './pages/image-upload-modal/image-upload-modal.page';
import { IframePage } from './pages/iframe-page/iframe-page.page';
import { UpdateFirstLastModalPage } from './pages/update-name-modal/update-name-modal.page';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [AppComponent, AvatarModalPage, ImageUploadModalPage, IframePage, UpdateFirstLastModalPage],

  entryComponents: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AppWideComponentsModule,
    IonicStorageModule.forRoot({
      name: '__hereafterdb',
      driverOrder: ['sqlite', 'indexeddb', 'websql', 'localstorage'],
    }),
    SyncedStoreModule,
    EffectsModule.forRoot([StorageSyncEffects]),
    // Store dev tools
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],

  providers: [
    AuthGuard,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeStorage,
    MediaCapture,
    File,
    Media,
    StreamingMedia,
    AudioService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
