import { EnvService } from './env.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AppState } from '../models/appState';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RecordingsActions } from '../reducers/recordings/recordings.actions';
import { Observable, Subscription, throwError, timer } from 'rxjs';
import { User } from '../models/user';
import { catchError, retryWhen, mergeMap, tap, filter } from 'rxjs/operators';
import {FormArray} from "@angular/forms";

function alertFirstInstance(i){
  if(i === 0){
    alert("We're sorry, there was a problem. Retrying");
  }
  return timer(1000);
}

@Injectable({
  providedIn: 'root',
})
export class RecordingStorageService {
  private userState$: Observable<User>;
  private userStateSubscription: Subscription;
  private user: User;
  numOfTries = 10;
  constructor(
    private storage: Storage,
    private _store: Store<AppState>,
    private http: HttpClient,
    private authService: AuthService,
    private env: EnvService
  ) {
    this.userState$ = this._store.select('user');
    this.userStateSubscription = this.userState$.subscribe((userState) => {
      this.user = userState;
    });
  }

  /*
    Responsible for saving the recording locally and passing all the needed information to the other function to be sent to the server
    Inputs: 
      audioFile: Captured recording from the record page
      recordingCategory: Category of the recording, taken from backend
  */
  storeRecording(audioFile, audioURL, recordingCategory){
    // console.log("Store recording inputs", audioFile, audioURL, recordingCategory);
    // Store it in Ionic Storage=
    // console.log("Inside recording storage storeRecording")
    // console.log(audioFile.name)
    this.storage.set(audioFile.name, audioFile)
      .then((storageResult) => {
        // console.log("Ionic storage results")
        // console.log(audioURL);
        // console.log(storageResult);
        this._store.dispatch(
          RecordingsActions.storeRecording({
            title: audioFile.name,
            category: recordingCategory
          })
        );
      })
      .catch((err) => {
        // console.error(err)
      });

    // Send it to the API
    return this.postRecordingToServer(audioFile, recordingCategory, this.authService.getAccessToken())
  }

  /*
    Send recording to backend for storage by adding the file to a FormData
    Inputs:
      audioFile: Recording file object
      recordingCategory: [not used]
      access_token: [not used]
  */
  postRecordingToServer(audioFile: File, recordingCategory: string, access_token: string) {
    // Needs to the recording category to the back-end
    // console.log("Inside postRecordingToServer");
    var audioData = new FormData();
    audioData.append('audio_object', audioFile, audioFile.name);
    // audioData.append('audio_object', audioFile, "vj_email");
    // audioData.append('audio_object', "email value");
    // console.log('--------Audio form before request:');
    let httpHeaders = new HttpHeaders();
    // console.log("Right before return");
    return this.http
      .post(this.env.API_URL + '/store_audio_object', audioData, {
        headers: {
          enctype: 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${this.user.token}`,
        },
      }).pipe(retryWhen(errors => errors.pipe( //Empty recording gives back the same error as no internet
        filter(value => value.status === 0),
        mergeMap((err, i) => 
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {
      throw 'internet'
    }));
  }

/*
    Send voice prompt to backend for communication wiht a legacy avatar
    Inputs:
      audioFile: Recording file object
  */
  requestAvatarResponse(audioFile: File){
    var audioData = new FormData();
    audioData.append('audio_object', audioFile, audioFile.name);
    // audioData.append('audio_object', audioFile, "vj_email");
    // audioData.append('audio_object', "email value");
    // console.log('--------Audio form before request:');
    let httpHeaders = new HttpHeaders();

    return this.http
      .post(this.env.API_URL + '/communicate_with_legacy_avatar', audioData, {
        headers: {
          enctype: 'multipart/form-data',
          Accept: 'application/json',
          Authorization: `Bearer ${this.user.token}`,
        },
      }).pipe(retryWhen(errors => errors.pipe(
        filter(value => value.status === 0),
        mergeMap((err, i) => 
          i + 1 === this.numOfTries
          ? throwError('Error from retry!')
          : alertFirstInstance(i)
        )
      )
    ), catchError((err) => {throw 'internet'}));
    // const FakeAPIResults = new Observable<any>((fakeResults)=>{
    //   fakeResults.next({
    //     "avatar_status": "success",
    //     "audio_clip_urls": ["https://storage.googleapis.com/automotive-media/Jazz_In_Paris.mp3"]
    //   })
    // })
    // return FakeAPIResults;
  }

}
