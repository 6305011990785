import { AudioStreamingService } from '../../services/audio-streaming.service';
import { APIService } from '../../services/api.service';
import { AlertController, ModalController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';

import { Storage } from '@ionic/storage';
import { NgForm } from '@angular/forms';
import { AppState } from 'src/app/models/appState';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { element } from 'protractor';
import { HelpModalPage } from '../help-modal/help-modal.page';
import { AvatarState } from 'src/app/models/avatarsState';
import AvatarsActions from 'src/app/reducers/avatars/avatars.actions';
import { AvatarModalPage } from '../avatar-modal/avatar-modal.page';
import { AuthService } from 'src/app/auth/auth.service';
import { AudioService } from '../../services/audio.service';
import { RecordingStorageService } from 'src/app/services/recording-storage.service';
import { EnvService } from 'src/app/services/env.service';
import UserActions from 'src/app/reducers/user/user.actions';
import { ErrorService } from 'src/app/services/error.service';
/*
  This code is used to run the demo James avatar in the main website. The implementation is the same as the listen page
  and avatar modal, expect that some values are hard-coded and that it is set as a demo avatar
*/

var DetectRTC = require('detectrtc')
DetectRTC.load(function() {
  DetectRTC.hasMicrophone; // (has microphone device!)
  DetectRTC.isMobileDevice;
});

export class Avatar {
  public name: string;
  public imageAddr: string;
  public expanded: boolean = false;
  public clipNumber: number;

  constructor(name: string, imageAddr: string, clipNumber:number, expanded: boolean = false) {
    this.name = name;
    this.imageAddr = imageAddr;
    this.expanded = expanded;
    this.clipNumber = clipNumber;
  }
}

@Component({
  selector: 'app-iframe-page',
  templateUrl: './iframe-page.page.html',
  styleUrls: ['./iframe-page.page.scss'],
})
export class IframePage implements OnInit {
  public avatarList: Avatar[] = [];
  playingIndex: number;
  avatarState$: Observable<AvatarState>;
  currentlyExpandedCategory: number = 0;
  playingCategoryIndex: number;
  // Desktop only:
  selectedAvatarIndex: number = 0;
  hydrated: boolean;
  hydrationStateSubscription: any;
  hydrationState$: any;
  silence: boolean;
  blockedMic: boolean;
  releaseMic: boolean = false;
  readyToRecord: boolean = true;
  currentlyRecording: boolean = false;
  audioFile: File;
  audioURL: string;
  public currentlyPlaying: HTMLAudioElement;
  public avatarSpeaking: boolean;
  private sessionID: string;
  public audiosQueue: HTMLAudioElement[] = [];
  public loadingAudio: boolean;
  public avatarReady: boolean;
  public firstImage;
  public needsRefresh: boolean;
  imageLoaded: boolean = false;
  public storyImages;
  private subscription;
  private navigatedAway: boolean = false;
  audioURLSQueue: string [] = [];
  urlArraySize: number;
  private accessToken = "itgtCXCUZxx7MRpQt5Fn";

  constructor(
    private storage: Storage,
    private apiService: APIService,
    private alertController: AlertController,
    private _store: Store<AppState>,
    private audioStreamingService: AudioStreamingService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public modalController: ModalController,
    private authService: AuthService,
    public audioService: AudioService,
    private recordingStorageService: RecordingStorageService,
    private router: Router,
    private env: EnvService,
    private errorService: ErrorService
  ) {
    // this.subscription = this.router.events.subscribe((data)=>{
    //   console.log(data)
    //   if (data instanceof NavigationStart){
    //     if(this.currentlyPlaying){
    //       if(!this.currentlyPlaying.paused){
    //         this.currentlyPlaying.pause();
    //       }
    //       this.audiosQueue.splice(0, this.audiosQueue.length);
    //       this.avatarSpeaking = false;
    //     }
    //     if(this.loadingAudio){
    //       this.navigatedAway = true;
    //     }
    //   }
    //   if(this.authService.isLoggedIn){
    //     this.authService.logout();
    //     this._store.dispatch(UserActions.logout());
    //   }
    // })
    route.params.subscribe(val =>{
      // this.authService.login(this.env.demoe, this.env.demokey).then((observable)=>{
        // observable.subscribe((response)=>{
          // if(response['login_status'] === 'success'){
            this.navigatedAway = false;
            this._store.dispatch(UserActions.setDemo());
            const explanation = document.getElementById("explanation");
            explanation.style.visibility = "visible";
            const arrow = document.getElementById("arrow");
            arrow.style.visibility = "visible";
            this.apiService.getSessionID(this.accessToken).subscribe((response)=>{
              if(response['generate_new_session_id_status'] === "success"){
                this.sessionID = response['session_id'];
              }
              else {
                var errCode = this.errorService.getErrorCode(response['generate_new_session_id_failure'])
                alert("Sorry, something went wrong. Error code: " + errCode);
              }
            }, (err)=> {
              if(err === 'internet'){
                alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
              }})
            this.apiService.getAvatars(this.accessToken).subscribe((res_full: any) => {
              // console.log(res_full)
              if(res_full['fetch_legacy_avatar_contacts_status'] === "success"){
                this._store.dispatch(
                  AvatarsActions.syncBackend({
                    avatars: res_full
                  })
                );
              }
              else {
                var errCode = this.errorService.getErrorCode(res_full['fetch_legacy_avatar_contacts_failure'])
                if(errCode === "EC-10"){
                  this.authService.logout();
                } else {
                  alert("Sorry, something went wrong. Error code: " + errCode);
                }
              }
            }, (err)=> {
              if(err === 'internet'){
                alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
              }});

            this.avatarState$ = this._store.select((state) => state.avatars);
            this.avatarState$.subscribe((avatars) => {
              let index = 0;
              this.needsRefresh = avatars.needsRefresh;
              // console.log("Here are the recordings categories");
              // console.log(recordings.categories);
              for (let key in avatars.avatars) {
                // Maps local state (e.g. expanded or not) from globally persisted state
                // console.log(key);
                // console.log(avatars.avatars[key]);
                this.avatarList[index] = new Avatar(key, avatars.avatars[key].image, avatars.avatars[key].clipNumber);
                if(index === 0){
                  this.firstImage = this.avatarList[index].imageAddr;
                }
                index++;
              }
            });
            // this._store.dispatch(
            //   UserActions.logout()
            // );
          // }
        // })
      // });
    })
  }
  ngOnInit() {
    const pic = <HTMLInputElement>document.getElementById("imageUpload");
    pic.addEventListener('change', (event)=>{
      // console.log("changed");
      var reader = new FileReader();
      var img = document.getElementById("avatar-profile-pic");
      reader.onload = function(event) {
        // console.log("loaded")
        img.setAttribute('src', ''+event.target.result);
      }
      reader.readAsDataURL(pic.files[0]);
      reader.addEventListener('loadend', (event)=>{
        this.avatarList[this.currentlyExpandedCategory].imageAddr = ''+reader.result;
        this._store.dispatch(AvatarsActions.updateImage({
            name: this.avatarList[this.selectedAvatarIndex].name,
            imageAddr: ''+reader.result
          }))
          this.selectCategory(this.selectedAvatarIndex);
      })
      var file_name = this.authService.getAccessToken().concat("__##__", this.selectedAvatarIndex.toString(), "__##__", this.sessionID, "__##__", pic.files[0].name);
      this.apiService.saveAvatarPic(pic.files[0], file_name).subscribe((response)=>{
        if(response['store_avatar_profile_status'] === 'success'){

        }
        else {
          var errCode = this.errorService.getErrorCode(response['store_avatar_profile_failure'])
          if(errCode === "EC-10"){
            this.authService.logout();
          } else {
            alert("Sorry, something went wrong. Error code: " + errCode);
          }
        }
      }, (err)=> {
        if(err === 'internet'){
          alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
        }})
    })
      // this.apiService.getAvatars().subscribe((res_full: any) => {
      //   // console.log(res_full)
      //   if(res_full['fetch_legacy_avatar_contacts_status'] === "success"){
      //     this._store.dispatch(
      //       AvatarsActions.syncBackend({
      //         avatars: res_full
      //       })
      //     );
      //   }
      //   else{
      //     console.error("API Error")
      //   }

      // });
  }

  ngOnDestroy(){
    // this.subscription.unsubscribe();
    // console.log(this.authService.isLoggedIn)
    // if(this.authService.isLoggedIn){
    //   this.authService.logout();
    //   this._store.dispatch(UserActions.logout());
    // }
  }

  handleScrollUp() {
    const topicSelector = document.querySelector('.avatar-selector');
    if (topicSelector instanceof HTMLElement) {
      topicSelector.scrollTop -= 51;
    }
  }

  handleScrollDown() {
    const topicSelector = document.querySelector('.avatar-selector');
    if (topicSelector instanceof HTMLElement) {
      topicSelector.scrollTop += 51;
    }
  }

  selectCategory(index) {
    // console.log("We've selected this category: " + index);
    this.apiService.getSessionID(this.accessToken).subscribe((response)=>{
      if(response['generate_new_session_id_status'] === "success"){
        this.sessionID = response['session_id'];
      }
      else {
        var errCode = this.errorService.getErrorCode(response['generate_new_session_id_failure'])
        if(errCode === "EC-10"){
          this.authService.logout();
        } else {
            alert("Sorry, something went wrong. Error code: " + errCode);
          }
      }
    }, (err)=> {
      if(err === 'internet'){
        alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
      }})
    this.selectedAvatarIndex = index;
    const pic = document.getElementById("avatar-profile-pic");
    const mini = document.getElementById("mini" + this.avatarList[this.selectedAvatarIndex].name);
    pic.setAttribute('src', this.avatarList[this.selectedAvatarIndex].imageAddr);
    mini.setAttribute('src', this.avatarList[this.selectedAvatarIndex].imageAddr);
    return false;
  }

  expandCategory(index) {
    this.currentlyExpandedCategory = index;
    this.avatarList[index].expanded = !this.avatarList[index].expanded;
    this.openConversation();
  }

  showWarning(avatarIndex){
    alert(
      "Sorry, I'm not ready to talk yet! Please go to the Record page and make at least "
      + this.avatarList[avatarIndex].clipNumber + " more recordings."
    );
  }

  async handleHelpClick() {
    const helpModal = await this.modalController.create({
      component: HelpModalPage,
      cssClass: 'helpModal',
    });
    helpModal.present();
  }

  async openConversation() {
    const helpModal = await this.modalController.create({
      component: AvatarModalPage,
      componentProps: {name: this.avatarList[this.currentlyExpandedCategory].name,
      image: this.avatarList[this.currentlyExpandedCategory].imageAddr,
      index: this.currentlyExpandedCategory,
      clipNumber:  this.avatarList[this.currentlyExpandedCategory].clipNumber },
      cssClass: 'helpModal',
    });
    helpModal.present();
  }

  changeProfilePic(){
    if(this.selectedAvatarIndex === 0){
      const pic = <HTMLInputElement>document.getElementById("imageUpload");
      pic.click();
    }
  }
  startRecording() {
    // console.log("Do we have permission?", DetectRTC.isWebsiteHasMicrophonePermissions)
    // console.log('startRecording called');
    // const explanation = document.getElementById("explanation");
    // explanation.style.visibility = "hidden";
    // const arrow = document.getElementById("arrow");
    // arrow.style.visibility = "hidden";
    if(this.currentlyPlaying){
      if(!this.currentlyPlaying.paused){
        this.currentlyPlaying.pause();
      }
    }
    this.audioURLSQueue.splice(0, this.audioURLSQueue.length);
    this.authService.checkTokenRefresh();
    if(this.avatarList[this.selectedAvatarIndex].clipNumber === 0){
      this.audioService.startRecording("Avatar-request", () =>{
        this.blockedMic = true;
      },()=>{
        this.releaseMic = true;
      });
      this.waitForMicPermission(()=>{
        this.readyToRecord = false;
        this.currentlyRecording = true;
        this.avatarSpeaking = false;
      }, ()=>{
        alert("Please enable the mic in the settings and refresh the page in order to use the app.");
      })
    }
    else{
      alert("Your avatar is not ready to speak yet! Please return to the \"Record\" page and share more memories.");
    }
  }

  waitForMicPermission(success, failure){
    let interval = setInterval(()=>{
      if(this.blockedMic){
        clearInterval(interval);
        failure();
      }
      // DetectRTC.load(function(){
      //   DetectRTC.isWebsiteHasMicrophonePermissions
      // })
      if(this.releaseMic){
        clearInterval(interval);
        success();
      }
    }, 300)
  }

  async stopRecording() {
    this.readyToRecord = true;
    this.audioService.stopRecording();
    this.currentlyRecording = false;
    let subscription = this.audioService.getRecordedBlob().subscribe((recording) => {
      this.audioURL = URL.createObjectURL(recording['blob']);
      // console.log(recording);
      // console.log(this.audioURL);
      // console.log('Captured: ' + recording['title']);
      var file_name = this.accessToken.concat("__##__", this.selectedAvatarIndex.toString(), "__##__", this.sessionID, "__##__", recording['title']);
      // console.log(file_name)
      this.audioFile = new File([recording['blob']], file_name, {
        type: 'audio/webm'
      });
      // console.log("Audio file inside stopRecording", this.audioFile)
      this.loadingAudio = true;
      this.recordingStorageService.requestAvatarResponse(this.audioFile).subscribe(async (data: any)=>{
        // console.log(data)
        if(data['sessionEnd'] === true){
          this.apiService.getSessionID().subscribe((response)=>{
            if(response['generate_new_session_id_status'] === "success"){
              this.sessionID = response['session_id'];
            }
            else {
              var errCode = this.errorService.getErrorCode(response['generate_new_session_id_failure'])
              if(errCode === "EC-10"){
                this.authService.logout();
              } else {
                alert("Sorry, something went wrong. Error code: " + errCode);
              }
            }
          }, (err)=> {
            if(err === 'internet'){
              alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
            }})
        }
        if(data['audio_clip_urls'].length > 0){
          if(!this.navigatedAway){
            this.urlArraySize = data['audio_clip_urls'].length;
            for (const element of data['audio_clip_urls']){
              this.audioURLSQueue.push(element)
            }
            if(data['image_urls'].length > 0){
              this.storyImages = data['image_urls'];
              this.imageLoaded = false;
            }
            else{
              this.imageLoaded = true;
            }
          }
        }else{
          this.loadingAudio = false;
          alert(
            "Sorry, there was an error while trying to retrieve information. Please refresh the page and try again."
          );
        }
        subscription.unsubscribe();
      })

    });
    this.currentlyPlaying= new Audio();
    await this.waitForURLS()
    this.handlePlay(this.audioURLSQueue.shift())

    this.audioService.stopRecording();
    this.audioService.teardownAudioMeter();
  }

  waitForURLS(){
    return new Promise<void>((resolve, reject)=>{
      let interval = setInterval(()=>{
        if(this.audioURLSQueue && this.audioURLSQueue.length == this.urlArraySize){
          clearInterval(interval);
          resolve();
        }
        else if(!this.loadingAudio){
          reject();
        }
      }, 500)
    })
  }

  handlePlay(audioURL){
    this.currentlyPlaying.src = audioURL;
    if(this.audioURLSQueue.length > 0){
      this.currentlyPlaying.onended = (event)=>{
        this.avatarSpeaking = false;
        this.handlePlay(this.audioURLSQueue.shift());
      }
      this.currentlyPlaying.onplaying = (event)=>{
        this.loadingAudio = false;
        this.avatarSpeaking = true;
        if(!this.imageLoaded){
          var modal = document.getElementById("myModal");
          modal.style.display = "flex";
          // var modalAux = document.getElementById("blur-header");
          // modalAux.style.visibility = "visible";
          var span = <HTMLElement>document.getElementsByClassName("close")[0];
          span.onclick = function() {
            modal.style.display = "none";
            // var modalAux = document.getElementById("blur-header");
            // modalAux.style.visibility = "hidden";
          }
          this.showSlides(0);
          this.imageLoaded = true;
        }
      }
        this.currentlyPlaying.play();
    }
    else if(this.audioURLSQueue.length === 0){
      this.currentlyPlaying.onended = (event)=>{
        this.avatarSpeaking = false;
        var modal = document.getElementById("myModal");
        modal.style.display = "none";
        // var modalAux = document.getElementById("blur-header");
        // modalAux.style.visibility = "hidden";
        this.imageLoaded = false;
      }
      this.currentlyPlaying.onplaying = (event)=>{
        this.loadingAudio = false;
        this.avatarSpeaking = true;
        if(!this.imageLoaded){
          var modal = document.getElementById("myModal");
          modal.style.display = "flex";
          // var modalAux = document.getElementById("blur-header");
          // modalAux.style.visibility = "visible";
          var span = <HTMLElement>document.getElementsByClassName("close")[0];
          span.onclick = function() {
            modal.style.display = "none";
            // var modalAux = document.getElementById("blur-header");
            // modalAux.style.visibility = "hidden";
          }
          this.showSlides(0);
          this.imageLoaded = true;
        }
      }
      this.currentlyPlaying.play();
    }
  }

  showSlides(slideIndex) {
    var i;
    var slides = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName("mySlides");
    // console.log(slides)
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    // if (slideIndex > slides.length) {return}
    slides[slideIndex-1].style.display = "block";
    if((this.storyImages.length > 1) && slideIndex < slides.length)
      setTimeout(()=>{this.showSlides(slideIndex);}, this.storyImages[slideIndex].transition_point*1000);
  }
}
