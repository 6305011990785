import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//Show tutorial video to help users
@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.page.html',
  styleUrls: ['./help-modal.page.scss'],
})
export class HelpModalPage implements OnInit {
  video;
  constructor(private modalController: ModalController, private router: Router) {}

    //Shows a different video for each page
  ngOnInit() {
    this.video = "assets/record-page-tutorial.mp4";
    if(this.router.url == '/record'){
    this.video = "assets/record-page-tutorial.mp4";
    }
    else if(this.router.url == '/library'){
    this.video = "assets/review-page-tutorial.mp4";
    }
    else if(this.router.url == '/memory-score'){
    this.video = "assets/status-page-tutorial.mp4";
    }
    else if(this.router.url == '/listen'){
    this.video = "assets/converse-page-tutorial.mp4";
    }
    else if (this.router.url== '/settings'){
    this.video = "";
      
    }
    else if (this.router.url== '/plans'){
    this.video = "";
      
    }
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
