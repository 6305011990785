import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toastController: ToastController) {}

  /*
    Displays a pop-up message irrelevant of the page that is
    displayed for 2500ms or whatever value is inputted
    Note: for some reason does not shown on mobile, but only thing that works for app
  */
  async presentToast(message: any, duration = 2500) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'bottom',
      color: 'primary',
    });
    toast.present();
  }
}
