import { NgModule, Injectable } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

/*
  loads each route of the application
*/
const routes: Routes = [
  {
    path: '',
    redirectTo: 'registration',
    pathMatch: 'full',
  },
  // {
  //   path: 'auth',
  //   loadChildren: () =>
  //     import('./pages/auth-page/auth.module').then((m) => m.AuthPageModule),
  // },
  {
    path: 'iframe',
    loadChildren: () =>
      import('./pages/iframe-page/iframe-page-routing.module').then((m) => m.IframePageRoutingModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./pages/registration/registration.module').then(
        (m) => m.RegistrationPageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordPageModule
      ),
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./pages/library/library.module').then((m) => m.LibraryPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'memory-score',
    loadChildren: () =>
      import('./pages/memory-score/memory-score.module').then(
        (m) => m.MemoryScorePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'record',
    loadChildren: () =>
      import('./pages/record/record.module').then((m) => m.RecordPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: ()=>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'listen',
    loadChildren: () =>
      import('./pages/listen/listen.module').then((m) => m.ListenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'blocked',
    loadChildren: () =>
      import('./pages/block-page/block.module').then((m) => m.BlockPageModule),
  },
  {
    path: 'plans',
    loadChildren: () =>
      import('./pages/plans-page/plans.module').then((m) => m.PlansPageModule),
      canActivate: [AuthGuard],
    },

  {
    path: 'success',
    loadChildren: () =>
      import('./pages/success-page/success.module').then(
        (m) => m.SuccessPageModule
      ),
  },
  {
    path: 'cancel',
    loadChildren: () =>
      import('./pages/cancel-page/cancel.module').then(
        (m) => m.CancelPageModule
      ),
  },
  // {
  //   // Wildcard redirect prevents modals from being shown
  //   path: '**',
  //   redirectTo: 'login',
  // },
];
@Injectable({
  providedIn: 'root',
})
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
