import { AuthService } from 'src/app/auth/auth.service';
import { Component } from '@angular/core';

import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

import { Platform, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HomePage } from './pages/home/home.page';
import { timer } from 'rxjs';

import {onLoad} from '../assets/data/googleLogin';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public showLoadingScreen = true;

  rootPage: any = HomePage;
  constructor(
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalCtrl: ModalController,
    private authService: AuthService
  ) {
    platform.ready().then(() => { //Set-up Google Analytics
      statusBar.styleDefault();
      gtag('config', 'UA-155377447-1', {send_page_view: false});
    });

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showLoadingScreen = true;
      if(event.url == '/registration' || event.url == '/login' || event.url=='/'){
        onLoad(); //Set-up google login
      }
      else{
        // this.authService.checkTokenRefresh();
      }
    }
    if (event instanceof NavigationEnd) {
      this.showLoadingScreen = false;
      if(gtag){
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
      }
      else{
        console.error("Google Analytics Error");
      }
      if(event.url == '/registration' || event.url == '/login'|| event.url=='/'){
        onLoad();
      }
      else{
        this.authService.checkTokenRefresh();
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showLoadingScreen = false;
    }
    if (event instanceof NavigationError) {
      console.error('Navigation Error', NavigationError);
      // console.log(event)
      this.showLoadingScreen = false;
    }
  }

  handleLogout() {
    this.authService.logout();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    timer(3000).subscribe(() => (this.showLoadingScreen = false));
  }
}
