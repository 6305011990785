import { createAction, props } from '@ngrx/store';
import { Message } from '../../models/messagesState';

interface optionsMap {
  newOptions: Map<string, string>;
}

interface recordingModeObj {
  recordingMode: boolean;
}
interface recordingModeCategory {
  recordingCategory: string;
}

interface recordingModeIntent {
  recordingIntent: any[];
}

interface imageModeObj {
  hasImage: boolean;
}
interface imageModeFile {
  image: File;
}

interface newDate {
  newDate: string;
}

interface numOfMessages{
  numOfMessages: number;
}

interface blockRevert {
  blockRevert: boolean;
}

export const MessagesActions = {
  setRecordingMode: createAction(
    '[Messages] Set Recording Mode',
    props<recordingModeObj>()
  ),
  setRecordingCategory: createAction(
    '[Messages] Set Recording Category',
    props<recordingModeCategory>()
  ),
  setRecordingIntent: createAction(
    '[Messages] Set Recording Intent',
    props<recordingModeIntent>()
  ),
  setImageMode: createAction(
    '[Messages] Set Image Mode',
    props<imageModeObj>()
  ),
  setImage: createAction(
    '[Messages] Save Image',
    props<imageModeFile>()
  ),
  updateLastAccessedDate: createAction(
    '[Messages] Update Last Accessed Date',
    props<newDate>()
  ),
  addMessage: createAction('[Messages] Add Message', props<Message>()),
  undoMessage: createAction('[Messages] Undo Message', props<numOfMessages>()),
  resetMessagesState: createAction('[Messages] ResetMessages'),
  resetOptions: createAction('[Messages] ResetOptions'),
  setOptions: createAction('[Messages] Set Options', props<optionsMap>()),
  updateRevert: createAction('[Messages] Update Revert', props<blockRevert>()),
};

export default MessagesActions;
