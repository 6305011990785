import { ModalController } from '@ionic/angular';
import { Component, Input, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/models/appState';
import { UserActions } from 'src/app/reducers/user/user.actions';
import { GlobalActions } from 'src/app/reducers/global.actions';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-update-name-modal',
  templateUrl: './update-name-modal.page.html',
  styleUrls: ['./update-name-modal.page.scss'],
})
export class UpdateFirstLastModalPage implements OnInit {
  @Input("landingPage") landingPage;
  userState$: any;
  user: any;
  constructor(private modalController: ModalController,
    private apiService: APIService,
    private authService: AuthService,
    private _store: Store<AppState>,
    private router: Router,
    private errorService: ErrorService) {
    }

  ngOnInit() {}

  ngOnDestroy() {
  }

  dismissModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }


//Get names from the form and send them to backend for saving
  updateFirstLast(form: NgForm) {
    if((form.value.fName.length > 0) && (form.value.lName.length > 0)){
      this.apiService.updateFirstLast(form.value.fName, form.value.lName).subscribe((response)=>{
        // console.log(response)
        if(response['update_first_last_status'] === 'success'){
          this._store.dispatch(
            UserActions.updateFirstLast()
          );
          this.router.navigateByUrl(this.landingPage);
          this.dismissModal();
        }
        else {
          var errCode = this.errorService.getErrorCode(response['update_first_last_failure'])
          if(errCode === "EC-10"){
            this.authService.logout();
          } else {
            alert("Sorry, something went wrong. Error code: " + errCode);
          }
        }
      }, (err)=> {
        if(err === 'internet'){
          alert("Sorry, your Internet connection is weak and may cause problems with the app. Try moving to a different location or changing your connection method.");
        }})
    }
  }
}
