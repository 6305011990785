import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.page.html',
  styleUrls: ['./image-upload-modal.page.scss'],
})
export class ImageUploadModalPage implements OnInit {
  error: boolean;
  error2:boolean;
  constructor(private modalController: ModalController,
    private apiService: APIService,
    private router: Router) {
      this.error = false;
      this.error2 = false;
    }

  ngOnInit() {}

     /**
   * on file drop handler
   */
  // onFileDropped($event) {
  //   console.log($event);
  // }

  //Get the image chosen by the user and return to previous page
  fileBrowseHandler(files) {
    if(files[0].type.match(/image/)){
      this.error = false;
      if(files[0].size < 31457280){
        this.modalController.dismiss(files[0])
      }
      else{
        this.error2 = true;
      }
    }
    else{
      this.error2 = false;
      this.error = true;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
