import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerHeaderMenuComponent } from '../drawer-header-menu/drawer-header-menu.component';
import { AppDesktopSidebarMenuComponent } from '../app-desktop-sidebar-menu/app-desktop-sidebar-menu.component';
import { SettingsTiersComponent } from '../subscription-tiers/subscription-tiers.component';


@NgModule({
  declarations: [
    DrawerHeaderMenuComponent,
    AppDesktopSidebarMenuComponent,
    SettingsTiersComponent
  ],
  imports: [CommonModule],
  exports: [DrawerHeaderMenuComponent, AppDesktopSidebarMenuComponent, SettingsTiersComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppWideComponentsModule {}
