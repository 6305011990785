import { createReducer, on, State, Action } from '@ngrx/store';
import { MessagesState, Message } from '../../models/messagesState';
import GlobalActions from '../global.actions';
import messagesActions from './messages.actions';

const initialMessagesState: MessagesState = {
  lastAccessed: '',
  messages: [],
  options: [],
  recordingMode: false,
  recordingCategory: '',
  hasImage: false,
  image: null,
  blockRevert: true,
  recordingIntent: []
};

function addArrayItem(array, item) {
  // This must be used in order to keep the state immutable. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns for more info
  return [...array.slice(0), item];
}

const _messagesReducer = createReducer(
  initialMessagesState,
  on(messagesActions.setRecordingMode, (state, { recordingMode }) => {
    const newState = {
      ...state,
      recordingMode: recordingMode,
    };
    return newState;
  }),
  on(messagesActions.setRecordingCategory, (state, { recordingCategory }) => {
    const newState = {
      ...state,
      recordingCategory: recordingCategory,
    };
    return newState;
  }),
  on(messagesActions.setRecordingIntent, (state, { recordingIntent }) => {
    const newState = {
      ...state,
      recordingIntent: recordingIntent,
    };
    return newState;
  }),
  on(messagesActions.setImageMode, (state, { hasImage }) => {
    // console.log("hasImage:", hasImage);
    const newState = {
      ...state,
      hasImage: hasImage,
    };
    return newState;
  }),
  on(messagesActions.setImage, (state, { image }) => {
    // console.log("Image:", image)
    const newState = {
      ...state,
      image: image,
    };
    return newState;
  }),
  on(messagesActions.updateLastAccessedDate, (state, { newDate }) => {
    // Persist the last accessed date, so that we can refresh every 24 hours in record.page.ts
    const newState = {
      ...state,
      lastAccessed: newDate,
    };
    return newState;
  }),
  on(messagesActions.addMessage, (state, Message) => {
    // Add a new message
    const newState = {
      ...state,
      messages: addArrayItem(state.messages, Message),
    };
    return newState;
  }),
  on(messagesActions.undoMessage, (state, {numOfMessages}) => { //Erase messages in the revert pipeline
    // Pop messages
    const newState = {
      ...state,
      messages: [...state.messages.slice(0, state.messages.length - numOfMessages)],
    };
    return newState;
  }),
  on(messagesActions.setOptions, (state, { newOptions }) => {
    // Refresh the messages - i.e. start from the top

    // We store this as an array because I am seeing some strange errors with Maps,
    // and they are mutable structures so don't play nice with Redux
    const newState = {
      ...state,
      options: Array.from(newOptions),
    };
    return newState;
  }),
  on(messagesActions.resetOptions, (state) => {
    // Refresh the messages - i.e. start from the top
    const newState = {
      ...state,
      options: [],
    };
    return newState;
  }),
  on(messagesActions.updateRevert, (state, {blockRevert}) =>{
    const newState = {
      ...state,
      blockRevert: blockRevert
    }
    return newState
  }),
  on(messagesActions.resetMessagesState, (state) => {
    // Refresh the messages - i.e. start from the top
    return initialMessagesState;
  }),
  on(GlobalActions.globalClear, (state) => {
    // Refresh the messages - i.e. start from the top
    return initialMessagesState;
  })
);

export default function messagesReducer(state, action) {
  return _messagesReducer(state, action);
}
