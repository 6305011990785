import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'subscription-tiers',
  templateUrl: './subscription-tiers.component.html',
  styleUrls: ['./subscription-tiers.component.scss'],
})
export class SettingsTiersComponent implements OnInit {
  public tiers = {
    "duo": {
      title: "Duo",
      access_accounts: 2,
      price: 9.99,
      description: "Let 2 people enjoy your stories."
    },
    "family": {
      title: "Family",
      access_accounts: 6,
      price: 12.99,
      description: "Let 6 people enjoy your stories."
    },
    "efamily": {
      title: "Extended Family",
      access_accounts: 10,
      price: 15.99,
      description: "Let 10 people enjoy your stories."
    },
    "custom": {
      title: "Custom",
      description: "Large groups, personal interviewers, transcripts, and more"
    }
  }
  @Input('tier') tier: string;
  @Input('current') current: boolean = false;
  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
  }
}
