import { createReducer, on, State, Action } from '@ngrx/store';
import { User } from '../../models/user';
import GlobalActions from '../global.actions';
import UserActions from './user.actions';

export const initialUserState: User = {
  token: '',
  // isAuthed: false,
  isLoggedIn: false,
  accessToken: '',
  refreshToken: '',
  access_token_creation_time: '',
  isDemo: false,
  update_first_last: true,
};

//Reducer starts at initialUserState at login and is then updated based on the called actions
const _userReducer = createReducer(
  initialUserState,
  // on(UserActions.auth, (state) =>{
  //   const newState = {
  //     // isAuthed: true,
  //     isLoggedIn: false,
  //     accessToken: '',
  //     refreshToken: '',
  //     access_token_creation_time: '',
  //   };
  //   return newState;
  // }),
  on(UserActions.login, (state, user) => {
    const newState = {
      token: user.token,
      isLoggedIn: user.isLoggedIn,
      // isAuthed: state.isAuthed,
      accessToken: user.accessToken,
      refreshToken: user.refreshToken,
      access_token_creation_time: user.access_token_creation_time,
      isDemo: false,
      update_first_last: user.update_first_last,
    };
    // console.log("Login new state", newState)
    return newState;
  }),
  on(UserActions.updateUser, (state, user) => {
    const newState = {
      ...state,
      token: user.token,
      isLoggedIn: user.isLoggedIn,
      // isAuthed: state.isAuthed,
      accessToken: user.accessToken,
      refreshToken: user.refreshToken,
      access_token_creation_time: user.access_token_creation_time,
      isDemo: false,
      update_first_last: user.update_first_last,
    };
    // console.log(newState)
    return newState;
  }),
  on(UserActions.clearUser, (state) => ({ ...initialUserState })),
  on(UserActions.updateFirstLast, (state)=>{ //This is used uniquely if there was a problem in registration and no name is listed for the user
      let newState = {
        ...state,
        update_first_last: false
      }
      // console.log(newState);
      return newState;
    }),
  // on(UserActions.logout, (state)=>{
  //   let newState = {
  //     ...state,
  //     isLoggedIn: false
  //   }
  //   // console.log(newState);
  //   return newState;
  // }),
  on(UserActions.setDemo, (state)=>{ //Used exclusevely for the demo
    let newState = {
      ...state,
      isDemo: true
    }
    // console.log(newState);
    return newState;
  }),
  on(GlobalActions.globalClear, (state) => {
    // Refresh the user - i.e. start from the top
    return {
      ...initialUserState
    }
  })
);

export default function userReducer(state, action) {
  return _userReducer(state, action);
}
