import jwt_decode from "jwt-decode";
let responsePayload;
export let logResults = async function() {
  responsePayload = "";
  await new Promise((resolve, reject)=>{
    let interval = setInterval(()=>{
      // console.log("Response", responsePayload);
      if(responsePayload){
        clearInterval(interval);
        resolve(responsePayload);
      }
    }, 300)
  });
  return responsePayload;
}
function handleCredentialResponse(response) {
    responsePayload = jwt_decode(response.credential);
    // console.log(responsePayload)
    // console.log("Encoded JWT ID token: " + response.credential);
  }
export let onLoad = function () {
  // console.log("onLoad");
    let your_function = function () {
      if(window.google){
        // console.log(google);
      window.google.accounts.id.initialize({
        client_id: "776212561468-p28b8bv99qbrgchsjk69pe92qkujmbm9.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      if(screen.width > 360){
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { type:"standard", theme: "outline", size: "large", text:"continue_with", logo_alignment:"center", shape:"pill", width:"300px" }  // customization attributes
        );
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDivL"),
          { type:"standard", theme: "outline", size: "large", text:"continue_with", logo_alignment:"center", shape:"pill", width:"300px" }  // customization attributes
        );
      }
      else{
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { type:"standard", theme: "outline", size: "large", text:"continue_with", logo_alignment:"center", shape:"pill", width:"250px" }  // customization attributes
        );
        window.google.accounts.id.renderButton(
          document.getElementById("buttonDivL"),
          { type:"standard", theme: "outline", size: "large", text:"continue_with", logo_alignment:"center", shape:"pill", width:"250px" }  // customization attributes
        );
      }
    }
  } 
  window.addEventListener ? 
    window.addEventListener("load",your_function,false) 
    : 
    window.attachEvent && window.attachEvent("onload",your_function);
  dispatchEvent(new Event('load'));
}