import { NgModule } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { storageSync } from './ionic-storage-sync';
import { StoreModule, Action } from '@ngrx/store';

// Reducers
import userReducer from './user/user.reducer';
import recordingsReducer from './recordings/recordings.reducer';
import messagesReducer from './messages/messages.reducer';

// AppState (overarching model)
import { AppState } from '../models/appState';
import avatarReducer from './avatars/avatars.reducer';

export const initialAppState = {
  user: {
    email: '',
    first_name: '',
    isLoggedIn: false,
  },
  hydrated: false,
};

export function hydrationReducer(state: boolean = false, action: Action): any {
  return state;
}

export const storageSyncReducer = storageSync({
  keys: ['user', 'recordings', 'messages'], // Sync the `user` and 'recordings' state
  ignoreActions: [],
  hydratedStateKey: 'hydrated', // Add this key to the state
  onSyncError: onSyncError, // If a sync fails
});

export const reducers: ActionReducerMap<AppState> = {
  hydrated: hydrationReducer,
  user: userReducer,
  messages: messagesReducer,
  recordings: recordingsReducer,
  avatars: avatarReducer
};

export function storageMetaReducer(
  reducer: ActionReducer<any>
): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export function onSyncError(err) {
  // console.log(err);
}

export const metaReducers: MetaReducer<any>[] = [storageMetaReducer];

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: initialAppState,
    }),
  ],
})
export class SyncedStoreModule {}
