import { createReducer, on, State, Action } from '@ngrx/store';
import { AvatarState } from '../../models/avatarsState';
import AvatarsActions from './avatars.actions';
import GlobalActions from '../global.actions';

const initialAvatarState: AvatarState ={
    needsRefresh: false,
    avatars: {},
}

const _avatarReducer = createReducer(
    initialAvatarState,
    on(AvatarsActions.syncBackend, (state, {avatars})=>{ //Sync legacy avatars from backend
        let newState = initialAvatarState;
        let i = 0;
        let needsRefresh = false;
        // console.log(state);
        avatars.legacy_avatar_contacts_payload.forEach( (element) => {
            state = newState
            let name = element;
            let image;
            if(avatars['legacy_avatar_profile_pictures'][i] == "default"){
                image = "../../assets/icons/default-avatar-profile-black.png";
            }
            else{
                image = avatars['legacy_avatar_profile_pictures'][i];
            }
            let clipNumber = avatars['legacy_avatar_minimum_clips_to_reach_level_2'][i];
            if(clipNumber > 0){
                needsRefresh = true;
            }
            i++;
            // console.log(element)
            newState = {
                ...state,
                avatars: {
                    ...state.avatars,
                    [name]: {
                        image: image,
                        clipNumber: clipNumber
                    },
            },
            };
        })
        state = newState;
        newState = {
          ...state,
          needsRefresh: needsRefresh
        }
      
    //   console.log("Persisting to:", newState);
      return newState;
    }),
    on(AvatarsActions.updateImage, (state, {name, imageAddr})=>{ //Update profile image
        let newState = {
            ...state,
            avatars:{
                ...state.avatars,
                [name]: {
                    image: imageAddr,
                }
            }
        }
        // console.log(newState)
        return newState
    }),
    on(AvatarsActions.eraseAvatar, (state, {index})=>{
        const avatar = Object.keys(state.avatars)[index];
        const newState = {
            ...state, 
            needsRefresh: true
        }
        // console.log(newState)
        return newState;
    }),
    on(GlobalActions.globalClear, (state)=>{
        let newState = initialAvatarState;
        return newState
    })
);

export default function avatarReducer(state, action) {
    return _avatarReducer(state, action);
  }