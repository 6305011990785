import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor() {}

  /*
    Receives an error message from a backend payload and returns relevant error code
  */

  getErrorCode(errorMessage) {
    if(errorMessage === "action not allowed"){
      return "EC-1"
    }
    else if (errorMessage === "there was an interface error, check input parameters"){
      return "EC-2"
    }
    else if (errorMessage === "unexpected error"){
      return "EC-3"
    }
    else if (errorMessage === "there was an admin error, check input parameters"){
      return "EC-4"
    }
    else if (errorMessage === "invalid route"){
      return "EC-5"
    }
    else if (errorMessage === "invalid third party"){
      return "EC-6"
    }
    else if (errorMessage === "unexpected error while saving to database"){
      return "EC-7"
    }
    else if (errorMessage === "forgot password email limit reached"){
      return "EC-8"
    }
    else if (errorMessage === "could not send reset password email"){
      return "EC-9"
    }
    else if (errorMessage === "token expired"){
      return "EC-10"
    }
    else if (errorMessage === "update first and last name failed"){
      return "EC-11"
    }
    else if (errorMessage === "the recording name did not exist to edit"){
      return "EC-12"
    }
    else if (errorMessage === "could not save audio"){
      return "EC-13"
    }
    else if (errorMessage === "could not process audio"){
      return "EC-14"
    }
    else if (errorMessage === "submitted token not validated"){
      return "EC-15"
    }
    else if (errorMessage === "failed to find the correct audio clip"){
      return "EC-16"
    }
    else if (errorMessage === "url creation"){
      return "EC-17"
    }
    else if (errorMessage === "update landing page failed"){
      return "EC-18"
    }
    else if (errorMessage === "update voice id failed"){
      return "EC-19"
    }
    else if (errorMessage === "update use vj voice failed"){
      return "EC-20"
    }
    else if (errorMessage === "no portal"){
      return "EC-21"
    }
    else if (errorMessage === "no account exists with that information"){
      return "EC-22"
    }
    else if (errorMessage === "account exists"){
      return "EC-23"
    }
    else if (errorMessage === "invalid password"){
      return "EC-24"
    }
    else if (errorMessage === "portal 2"){
      return "EC-26"
    }
    else if (errorMessage === "portal 3"){
      return "EC-27"
    }
    else{
      return "EC-25"
    }
  }
}
